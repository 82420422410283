import { PureComponent } from 'react';
import Col from 'react-bootstrap/esm/Col';

import { withTrans } from '@shares/locales/hoc';
import SectionLayout from '@pages/extend-rental/component/SectionLayout';

import { SectionExpireProps } from './interface';
import ButtonLink from '../../component/ButtonLink';

import './style.scss';

class SectionExpire extends PureComponent<SectionExpireProps> {
  render() {
    return (
      <Col lg={4} className="p-0">
        <SectionLayout>
          <div className="d-flex justify-content-center">
            <img height="75" alt="error" src="/assets/pages/extend-rental/icon-link-expired.svg" />
          </div>
          <div className="title text-center py-3">
            <p>ลิงค์ในการขยายการเช่า</p>
            <p>หมดอายุแล้ว</p>
          </div>
          <div className="mb-3 sub-title">โปรดติดต่อเจ้าหน้าที่ Drivehub</div>
          <ButtonLink />
        </SectionLayout>
      </Col>
    );
  }
}

export default withTrans('extend_rental.expire')(SectionExpire);
