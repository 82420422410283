import { PureComponent } from 'react';
import Col from 'react-bootstrap/esm/Col';
import { StaticImage } from 'gatsby-plugin-image';

import SectionLayout from '@pages/extend-rental/component/SectionLayout';
import { withTrans } from '@shares/locales/hoc';

import { SectionSuccessProps } from './interface';

import './style.scss';

class SectionSuccess extends PureComponent<SectionSuccessProps> {
  render() {
    return (
      <Col lg={4} className="p-0">
        <SectionLayout>
          <div className="d-flex justify-content-center">
            <StaticImage alt="error" src="../../../../../images/extend-rental/icon-success.svg" className="mb-2" />
          </div>
          <div className="title text-center py-3">
            <p>คำขอถูกส่งแล้ว</p>
            <p>โปรดรอการติดต่อกลับจาก</p>
            <p>เจ้าหน้าที่ Drivehub</p>
          </div>
          <div className="mb-3 sub-title">เพื่อยืนยันการขยายเวลาการเช่ารถของคุณ</div>
          <div className="remark">
            <p>** คำขอขยายเวลาการเช่าอาจได้รับการปฏิเสธ</p>
            <p>หากบริษัทเช่ารถไม่สามารถขยายเวลาให้คุณได้</p>
          </div>
        </SectionLayout>
      </Col>
    );
  }
}

export default withTrans('extend_rental.expire')(SectionSuccess);
