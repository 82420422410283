import { PureComponent } from 'react';
import Col from 'react-bootstrap/esm/Col';

import { withTrans } from '@shares/locales/hoc';
import SectionLayout from '@pages/extend-rental/component/SectionLayout';

import { SectionErrorProps } from './interface';
import ButtonLink from '../../component/ButtonLink';

import './style.scss';

class SectionError extends PureComponent<SectionErrorProps> {
  render() {
    return (
      <Col lg={4} className="p-0">
        <SectionLayout>
          <div className="d-flex justify-content-center">
            <img height="55" alt="error" src="/assets/pages/extend-rental/icon-error.svg" className="mb-2" />
          </div>
          <div className="title text-center py-3 ">
            <p>ระบบเกิดข้อผิดพลาด</p>
            <p>โปรดลองใหม่อีกครั้ง</p>
          </div>
          <div className="mb-3 sub-title">หรือติดต่อ</div>
          <ButtonLink />
        </SectionLayout>
      </Col>
    );
  }
}

export default withTrans('extend_rental.expire')(SectionError);
