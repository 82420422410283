import { withTrans } from '@shares/locales/hoc';
import { PureComponent } from 'react';
import Button from 'react-bootstrap/esm/Button';
import { dataLayer } from '@ui/utils/datalayer';
import './style.scss';

class ButtonLink extends PureComponent {
  render() {
    return (
      <div className="d-flex flex-column">
        <Button variant="outline-success" className="btn-extend btn-extend--line mb-3" size="sm">
          <a
            data-event-category="header_section"
            data-event-action="contact_us"
            data-event-label="line"
            rel="nofollow"
            href="https://lin.ee/axOqULu"
            onClick={(e) => dataLayer(e)}
          >
            <div className="line-icon" /> @drivehub
          </a>
        </Button>
        <Button variant="outline-primary" className="btn-extend" size="sm">
          <a
            data-event-category="header_section"
            data-event-action="contact_us"
            data-event-label="callcenter"
            rel="noreferrer"
            href="tel:+6620385222"
            onClick={(e) => dataLayer(e)}
          >
            <i className="icon-phone"></i> 02-038-5222
          </a>
        </Button>
      </div>
    );
  }
}

export default withTrans('extend_rental.expire')(ButtonLink);
