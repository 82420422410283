import { PureComponent } from 'react';
import './style.scss';

class SectionLayout extends PureComponent {
  render() {
    return <div className="section-layout">{this.props.children}</div>;
  }
}

export default SectionLayout;
