import { useCallback, useEffect, useState } from 'react';
import Container from 'react-bootstrap/esm/Container';
import Row from 'react-bootstrap/esm/Row';
import { parse } from 'query-string';
import axios from 'axios';

import SectionExpire from '@pages/extend-rental/section/expire';
import SectionError from '@pages/extend-rental/section/error';
import SectionConfirm from '@pages/extend-rental/section/confirm';
import SectionSuccess from '@pages/extend-rental/section/success';

import Context from '@ui/components/context';
import { DRIVEHUB_API } from '@ui/config';

import { PageSection } from '@utils';
import Layout from '@shares/layout';
import { canUseDOM } from '@ui/components/utils';

import { Props } from './interface';
import './style.scss';

const ExtendRentalPage: React.FunctionComponent<Props> = ({ pageContext }) => {
  const [responseCode, setResponseCode] = useState<number>(200);
  const [confirmExtend, setConfirmExtend] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);

  const { prefix } = pageContext;

  const getResource = () => {
    let bookingID = '0';
    let lastName = '';
    if (canUseDOM()) {
      bookingID = parse(window.location.search).booking_id as string;
      lastName = parse(window.location.search).last_name as string;
    }
    return { bookingID, lastName };
  };

  useEffect(() => {
    const { bookingID, lastName } = getResource();
    axios
      .get(`${DRIVEHUB_API}/v2/bookings/${bookingID}/check_extend_rental_expire?last_name=${lastName}`, {})
      .catch((error) => {
        setResponseCode(error.response.status);
      });
    setLoading(false);
  }, []);

  const onClickConfirmExtend = useCallback(async () => {
    setLoading(true);
    const { bookingID, lastName } = getResource();
    await axios({
      method: 'post',
      url: `${DRIVEHUB_API}/v2/bookings/${bookingID}/request_extend_rental`,
      headers: { 'content-type': 'application/json' },
      data: {
        last_name: lastName
      }
    })
      .then(() => {
        setConfirmExtend(true);
      })
      .catch((error) => {
        setResponseCode(error.response.status);
      });
    setLoading(false);
  }, []);

  const renderExtendRentalSection = () => {
    const { bookingID } = getResource();
    switch (responseCode) {
      case 200:
        return confirmExtend ? (
          <SectionSuccess />
        ) : (
          <SectionConfirm isLoading={loading} bookingID={bookingID} onClick={onClickConfirmExtend} />
        );
      case 401:
        return <SectionExpire />;
      default:
        return <SectionError />;
    }
  };

  return (
    <Context>
      <Layout section={PageSection.ExtendRental} prefix={prefix} isTransparent={false}>
        <div className="extend-rental-box">
          <Container className="extend-rental">
            <Row className="justify-content-center">{loading ? <div>loading</div> : renderExtendRentalSection()}</Row>
          </Container>
        </div>
      </Layout>
    </Context>
  );
};

export default ExtendRentalPage;
