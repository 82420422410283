import { PureComponent } from 'react';
import Button from 'react-bootstrap/esm/Button';
import Col from 'react-bootstrap/esm/Col';
import { StaticImage } from 'gatsby-plugin-image';

import SectionLayout from '@pages/extend-rental/component/SectionLayout';
import { withTrans } from '@shares/locales/hoc';

import { SectionConfirmProps } from './interface';

import './style.scss';

class SectionConfirm extends PureComponent<SectionConfirmProps> {
  render() {
    const { isLoading, bookingID, onClick } = this.props;

    return (
      <Col lg={4} className="p-0">
        <SectionLayout>
          <div className="d-flex justify-content-center">
            <StaticImage alt="error" src="../../../../../images/extend-rental/icon-confirm.svg" className="mb-2" />
          </div>
          <div className="title text-center py-3">
            <p>คุณต้องการขยายเวลาการ</p>
            <p>เช่ารถใช่เหรือไม่?</p>
          </div>
          <div className="mb-3 sub-title">การจอง #{bookingID}</div>
          {isLoading ? (
            <div style={{ width: 50, height: 40 }} className="fetching" />
          ) : (
            <Button className="btn-sumbit w-100">
              <a onClick={onClick}>ยืนยันขยายเวลาการเช่า</a>
            </Button>
          )}
        </SectionLayout>
      </Col>
    );
  }
}

export default withTrans('extend_rental.expire')(SectionConfirm);
